import React, { Fragment } from 'react';
import { Container, CardBody, Input, Col, Form, Row, FormGroup, Label } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './PasswordInput.css';
import { toast } from 'react-toastify';
import { validPassword } from '../validations/validation.jsx';
import { authOperations } from '../../state/ducks/auth';
import { connect } from 'react-redux';
import GoogleFontLoader from 'react-google-font-loader';

class ResetPassword extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showNewPassword: false,
      showCofirmPassword: false,
      newPassword: '',
      confirmPassword: '',
      selectedMenu: ''
    };
  }

  toggleNewPasswordVisibility = () => {
    this.setState(prevState => ({
      showNewPassword: !prevState.showNewPassword
    }));
  }

  toggleCofirmPasswordVisibility = () => {
    this.setState(prevState => ({
      showCofirmPassword: !prevState.showCofirmPassword
    }));
  }

  handleChange = async (e) => {
    try {
      let tid = e.target.id;
      switch (tid) {
        case 'newPassword':
          const newPassword = e.target.value;
          const isPasswordValid = !newPassword || validPassword.test(newPassword);
          this.setState({ newPassword: newPassword, isPasswordValid });
          break;
        default:
          console.log("No Matching target found");
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  onInsert = async (event) => {
    event.preventDefault();
    try {
      let user = sessionStorage.getItem('user');
      if (user) {
        user = JSON.parse(user);
        this.setState({ user });
      }
      const payload = {
        // id: user.userId,
        newPassword: this.state.newPassword,
        confirmPassword: this.state.confirmPassword
      }
      const response = await this.props.resetPassword(payload);
      toast.success(response.message, { autoClose: 10000 });
      window.open("/reset-password/", "_self");
    } catch (err) {
      toast.error(err.response.data.message, { autoClose: 10000 });
      console.log(err);
    }
  }

  // handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const { newPassword, confirmPassword } = this.state;
  //   if (newPassword !== confirmPassword) {
  //     // Handle password mismatch error
  //     return;
  //   }
  //   try {
  //     const options = {
  //       url: API_URL.BACKEND_SERVICE_API_URL + '/user/user-reset-password',
  //       method: 'POST',
  //       data: {
  //         newPassword: newPassword,
  //         confirmPassword: confirmPassword
  //       }
  //     };
  //     const response = await request(options);
  //     toast.success(response.message, { autoClose: 10000 });
  //     window.open("/reset-password/", "_self");
  //     // Handle success response
  //   } catch (err) {
  //     // Handle error response
  //     toast.error(err.response.data.message, { autoClose: 10000 });
  //     console.log(err);
  //   }
  // }

  handleMenuClick = (menu) => {
    this.setState({ selectedMenu: menu });
  };

  render() {
    const { selectedMenu, showNewPassword, showCofirmPassword, newPassword, confirmPassword } = this.state;
    const ulStyle = {
      display: 'flex',
      color: "#a186be",
      listStyle: 'none',
      padding: 0,
      textAlign: 'center',
      justifyContent: 'center',
      fontWeight: "300",
      fontFamily: "Fira Sans",
      fontSize: ".8rem"
    };

    const spanStyle = {
      background: "#fdc1ea",
      display: "inline-block",
      height: "20px",
      width: "1px",
      margin: "0 20px"
    };

    const liStyle = {
      cursor: 'pointer',
      paddingBottom: '5px' // Add some padding to make the border more visible
    };
    return (
      <Fragment>
        <GoogleFontLoader
          fonts={[
            {
              font: 'Martel',
              weights: [300, 700],
            },
            {
              font: 'Fira Sans',
              weights: [300, 700, 400],
            },
          ]}
        />
        <Container fluid={true}>
          <CardBody>
            <Form onSubmit={this.onInsert}>
              <Row>
                <h2 className='mt-5' style={{ textAlign: 'center', font: 'normal normal 300 2.2rem/1.4 Martel', color: "#000" }}>My Account</h2>
                <div style={{ textAlign: 'center' }}>
                  <span style={{ borderBottom: "1px solid #FEC1EB", height: "1px", width: "102px", display: "inline-block" }}></span>
                </div>
                <ul className='mt-4 myAccountMenu' style={ulStyle}>
                  <li
                    style={selectedMenu === 'billing' ? { ...liStyle, borderBottom: '2px solid #000' } : liStyle}
                    onClick={() => this.handleMenuClick('billing')}
                  >
                    <Link to="/billing/" style={{ color: 'inherit', textDecoration: 'none' }}>BILLING</Link>
                  </li>
                  <span style={spanStyle}></span>
                  <li
                    style={selectedMenu === 'my-details' ? { ...liStyle, borderBottom: '2px solid #000' } : liStyle}
                    onClick={() => this.handleMenuClick('my-details')}
                  >
                    <Link to="/my-details/" style={{ color: 'inherit', textDecoration: 'none' }}>MY DETAILS</Link>
                  </li>
                  <span style={spanStyle}></span>
                  <li
                    style={selectedMenu === 'emails' ? { ...liStyle, borderBottom: '2px solid #000' } : liStyle}
                    onClick={() => this.handleMenuClick('emails')}
                  >
                    <Link to="/emails/" style={{ color: 'inherit', textDecoration: 'none' }}>EMAILS</Link>
                  </li>
                  <span style={spanStyle}></span>
                  <li
                    style={selectedMenu === 'reset-password' ? { ...liStyle, borderBottom: '2px solid #000' } : liStyle}
                    onClick={() => this.handleMenuClick('reset-password')}
                  >
                    <Link to="/reset-password/" style={{ color: 'inherit', textDecoration: 'none' }}>RESET PASSWORD</Link>
                  </li>
                </ul>
              </Row>
              <Row>
                {/* <Col md='2'></Col> */}
                <Col md='12' style={{maxWidth: "950px", margin: "0 auto"}}>
                  <div className='billingMyStoryTitle' style={{ fontSize: "17px", fontWeight: "400", fontFamily: "Martel", borderBottom: "1px solid #fdc1ea", paddingBottom: "12px", marginBottom: "30px" }}>Choose password</div>
                </Col>
                {/* <Col md='2'></Col>
                <Col md='2'></Col> */}
                <Col md='12' style={{maxWidth: "950px", margin: "0 auto", padding: "0 15px"}}>
                  <Row style={{margin: "0"}}>
                    <Col xs={12} style={{ background: "#EEE", padding: "2% 2% 0% 4%" }}>
                      <FormGroup className="mb-3 mt-3">
                        <Label style={{ font: "normal normal 300 1.0rem/1.5 Fira Sans" }}>New Password<span style={{ color: "#F61C04" }}>*</span></Label>
                        <div className="password-input-container">
                          <Input
                            type={showNewPassword ? "text" : "password"}
                            name="newPassword"
                            id="newPassword"
                            value={this.state.newPassword || ''}
                            onChange={this.handleChange}
                          // onChange={e => this.setState({ newPassword: e.target.value })}
                          />
                          {this.state.isPasswordValid ? <span></span> : <span style={{ color: "#F61C04" }}>Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, and one digit.</span>}
                          <FontAwesomeIcon
                            icon={showNewPassword ? faEyeSlash : faEye}
                            className="password-toggle-icon"
                            onClick={this.toggleNewPasswordVisibility}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col xs={12} style={{ background: "#EEE", padding: "0% 2% 3% 4%" }}>
                      <FormGroup className="mb-3">
                        <Label style={{ font: "normal normal 300 1.0rem/1.5 Fira Sans" }}>Confirm Password<span style={{ color: "#F61C04" }}>*</span></Label>
                        <div className="password-input-container">
                          <Input
                            type={showCofirmPassword ? "text" : "password"}
                            // name="cpassword"
                            // id="cpassword"
                            // value={confirmPassword}
                            onChange={e => this.setState({ confirmPassword: e.target.value })}
                          />
                          <FontAwesomeIcon
                            icon={showCofirmPassword ? faEyeSlash : faEye}
                            className="password-toggle-icon"
                            onClick={this.toggleCofirmPasswordVisibility}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className='mt-3'>
                    {/* <Col xs={5}></Col> */}
                    <Col xs={12} style={{textAlign: "center"}}>
                      <button type='submit' className='custom-button' style={{margin: "0 auto"}}>Submit</button>
                    </Col>
                    {/* <Col xs={5}></Col> */}
                  </Row>
                </Col>
                {/* <Col md='2'></Col> */}
              </Row>
              <Row className='mt-2'>
                <span style={{ borderBottom: "1px solid #ccc", height: "2px", width: "880px", margin: "10px auto" }}></span>
              </Row>
              <Row className='mt-1'>
                {/* <Col xs={5}></Col> */}
                <Col xs={12} style={{textAlign:"center"}}>
                  <button type='submit' className='custom-button' style={{margin: "0 auto"}} onClick={this.onBtnClick}>{"View my stories "}<svg xmlns="http://www.w3.org/2000/svg" class="feather feather-chevron-right" fill="none" height="24" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24"><polyline points="9 18 15 12 9 6"/></svg></button>
                </Col>
                {/* <Col xs={4}></Col> */}
              </Row>
              <Row className='mt-4 mb-5'>
                <ul style={ulStyle}>
                  <li style={liStyle}><Link to="https://gys.coalescets.com/">BUY A STORY</Link></li>
                  <span style={spanStyle}></span>
                  <li style={liStyle}><Link to="https://gys.coalescets.com/">GIFT A STORY</Link></li>
                </ul>
              </Row>
            </Form>
          </CardBody>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = () => { return {} };

const mapDispatchToProps = {
  resetPassword: authOperations.resetPassword
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);