import React, { Fragment } from 'react'
import {
  Container,
  Card,
  CardBody,
  Input,
  Col,
  Form,
  Row,
  Label,
} from 'reactstrap'
import { myStoryOperations } from '../../state/ducks/story/index.js'
import list from '../../assets/images/datatable/list.png'
import { connect } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'
import { toast } from 'react-toastify'
import request from '../../utils/request.js'
import * as API_URL from '../../env.js'
import '../Button.css'
import GoogleFontLoader from 'react-google-font-loader'
import AddAnotherQuestios from './AddAnotherQuestion'

class SendQuestions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedOption: '',
      showDetails: false,
      selectedCategory: '',
      showSecondDropdown: false,
      selectedQuestionText: '',
      selectedQuestionDescription: '',
      category: [],
      question: [],
      language: [],
      selectedQuestion: '',
      selectedGiftUserData: {},
      selectedGiftUserOrderData: [],
      numCols: 1,
      onAdd: false,
      GiftUserOrderData: {},
    }
  }

  async componentDidMount() {
    try {
      // this.loadLocalStorageData();
      this.GiftUserData()
    } catch (err) {
      console.log(err)
    }
  }

  async GiftUserData() {
    try {
      const { loggedInUserId } = this.props
      if (!loggedInUserId) {
        console.log('No user ID found')
        return
      }

      const options = {
        url: API_URL.BACKEND_SERVICE_API_URL + `/user/gift-order-details`,
        method: 'GET',
        headers: {
          authorization: sessionStorage.getItem('token'),
        },
      }
      let response = await request(options)
      //console.log("Response===>>", response);
      const id = parseInt(sessionStorage.getItem('selectedGiftUserId'), 10)
      // const id = sessionStorage.getItem("selectedGiftUserId");
      // console.log("id========",id)

      const filterGiftOrder = response.payload.userOrders.find(
        (order) => order.orderId === id
      )
      // console.log("selectedGiftUserId==",filterGiftOrder,"====", response.payload.userOrders);
      this.setState({
        selectedGiftUserData: response.payload.user,
        GiftUserOrderData: filterGiftOrder,
      })
      // this.setState({
      //   selectedGiftUserOrderData: response.payload.userOrders,
      // });
      // this.setState({ selectedOrderData: response.payload });
    } catch (err) {
      console.log(err)
    }
  }

  loadLocalStorageData = async () => {
    //Store category
    let category = sessionStorage.getItem('category')
    if (category) {
      category = JSON.parse(category)
      this.setState({ category })
    } else {
      let response = await this.props.listCategory()
      if (response.payload.length !== 0) {
        const searchString = JSON.stringify(response.payload)
        sessionStorage.setItem('category', searchString)
        category = JSON.parse(searchString)
        this.setState({ category })
      }
    }

    let language = sessionStorage.getItem('language')
    if (language) {
      language = JSON.parse(language)
      this.setState({ language })
    } else {
      let response = await this.props.listLanguage()
      // console.log('Language Response:', response);
      if (response.payload.length !== 0) {
        const searchString = JSON.stringify(response.payload)
        // console.log("searchString=====",searchString);
        sessionStorage.setItem('language', searchString)
        language = JSON.parse(searchString)
        this.setState({ language })
      }
    }
  }

  onInsert = async (event) => {
    event.preventDefault()
    try {
      const { GiftUserOrderData } = this.state

      // Filter to find the first gift user with a non-empty name
      // const filteredGiftUserOrderData = GiftUserOrderData.filter(
      //   (order) => order.giftUserName && order.giftUserName.trim() !== ""
      // );

      // Check if there's at least one valid gift user
      // if (filteredGiftUserOrderData.length === 0) {
      //   toast.error("No valid gift user data found", { autoClose: 10000 });
      //   return;
      // }

      // Extract the email and name from the first valid gift user
      const { giftUserEmail, giftUserName } = GiftUserOrderData //filteredGiftUserOrderData[0];
      const payload = {
        email: giftUserEmail, // Email of the gift user
        name: giftUserName,
        question: this.state.question,
        // this.state.showSecondDropdown
        //   ? this.state.selectedQuestionData.question
        //   : this.state.question,
        // description: this.state.showSecondDropdown ? this.state.selectedQuestionData.question_description : this.state.description,
      }
      const response = await this.props.sendQuestion(payload)
      //console.log("response=======>>>>", response);
      toast.success(response.message, { autoClose: 10000 })
      window.open('/thank-you-for-your-question/', '_self')
    } catch (err) {
      toast.error(err.response?.data?.message, { autoClose: 10000 })
      console.log(err)
    }
  }

  // onInsert = async (event) => {
  //   event.preventDefault();
  //   try {
  //     const { questions } = this.state;
  //     const giftOrderDetails = this.props.giftOrderDetails; // Assuming you have this data in props
  //     const userOrders = giftOrderDetails.payload.userOrders;
  // // console.log("userOrders=====>>>>>>>>>",giftOrderDetails, userOrders);
  //     // Extract the relevant gift user details
  //     const giftUserDetails = userOrders
  //       .filter(order => order.giftUserName && order.giftUserEmail)
  //       .map(order => ({
  //         email: order.giftUserEmail,
  //         name: order.giftUserName,
  //         question: questions, // Assuming questions is a single question or you want to send the same question to all
  //       }));

  //     // Loop through each gift user and send the question
  //     for (const userDetails of giftUserDetails) {
  //       // POST request to send the question(s) to each gift user
  //       const response = await this.props.sendQuestion(userDetails);
  //       // console.log("respons-===-=-=-=->>>>",response);
  //       toast.success(response.message, { autoClose: 10000 });
  //     }

  //     // Redirect or clear the form after submission
  //     window.open("/thank-you-for-your-question/", "_self");
  //   } catch (err) {
  //     toast.error(err.response, { autoClose: 10000 });
  //     console.log(err);
  //   }
  // };

  // handleSendQuestion = async (e) =>{

  //   let response = await this.props.sendQuestion({
  //   });
  //   if (response.payload && response.payload.length !== 0) {
  //   } else {
  //     this.setState({ });
  //   }
  // }

  handleAddCol = () => {
    // this.setState({});
    this.setState((prevState) => ({
      // selectedOption: "",
      // showDetails: false,
      // showSecondDropdown: false,
      // selectedCategory:'',
      // selectedQuestionText: "",
      // selectedQuestionDescription: "",
      onAdd: true,
      numCols: prevState.numCols + 1,
    }))
  }

  handleRemoveCol = () => {
    alert('Are you sure you want to remove this group?')
    if (this.state.numCols > 0) {
      this.setState((prevState) => ({
        numCols: prevState.numCols - 1,
      }))
    }
  }

  handleOptionChange = (e) => {
    const selectedOption = e.target.value
    this.setState({ selectedOption, showDetails: true })
  }

  handleOptionQuestionChange = async (e) => {
    const selectedQuestionId = e.target.value
    try {
      const options = {
        url:
          API_URL.BACKEND_SERVICE_API_URL +
          `/question/get-Question-details/${selectedQuestionId}`, // change this static id to wooCommerce UserId
        method: 'GET',
        headers: {
          authorization: sessionStorage.getItem('token'), // change this token to wooCommerce user token
        },
      }
      let response = await request(options)
      // console.log("question====>>>",response.payload);
      const selectedQuestionData = response.payload
      this.setState({ selectedQuestionData })
    } catch (err) {
      console.log(err)
    }
  }

  handleCategoryChange = async (e) => {
    const selectedCategory = e.target.value
    this.setState({
      selectedCategory,
      showSecondDropdown: selectedCategory !== '',
    })
    let response = await this.props.listQuestionByCategory({
      category_id: selectedCategory,
    })
    if (response.payload && response.payload.length !== 0) {
      const searchString = JSON.stringify(response.payload)
      sessionStorage.setItem('question', searchString)
      const question = JSON.parse(searchString)
      this.setState({ searchString })
      this.setState({ question })
    } else {
      // console.log("No questions fetched for the selected category.");
      sessionStorage.setItem('question', JSON.stringify([]))
      this.setState({ question: [] })
    }
  }

  handleLanguageChange = async (e) => {
    const selectedLanguage = e.target.value
    let category = sessionStorage.getItem('category')
    category = JSON.parse(category)
    const language = selectedLanguage === 'English' ? category : []

    this.setState({
      selectedLanguage,
      category: language,
      selectedQuestionData: selectedLanguage === 'English',
    })
    let response = await this.props.listQuestionByLanguage({
      language_name: selectedLanguage,
    })
    if (response.payload && response.payload.length !== 0) {
      const searchString = JSON.stringify(response.payload)
      // console.log("searchingString====>>><><<><><><", searchString);
      sessionStorage.setItem('question', searchString)
      const question = JSON.parse(searchString)
      this.setState({ searchString })
      this.setState({ question })
    } else {
      // console.log("No questions fetched for the selected language.");
      sessionStorage.setItem('question', JSON.stringify([]))
      this.setState({ question: [] })
    }
  }
  render() {
    const {
      category,
      language,
      question,
      selectedGiftUserData,
      selectedGiftUserOrderData,
      selectedQuestionData,
      numCols,
      onAdd,
      GiftUserOrderData,
    } = this.state
    //console.log("GiftUserOrderData==>>>>",GiftUserOrderData);

    // const filteredGiftUserOrderData = selectedGiftUserOrderData.filter(
    //   (order) => order.giftUserName.trim() !== ""
    // );
    ;<AddAnotherQuestios
      handleRemoveCol={() => this.handleRemoveCol()}
      onAdd={this.state.onAdd}
    />
    // const colComponents = Array.from({ length: numCols }, (_, index) => (
    //   <Row className="mt-3">
    //     {/* <Col md='2'></Col> */}
    //     <Col md="12">
    //       <div style={{ background: "#8A1A75", padding: "1.5%" }}>
    //         <img src={list} width="8" height="20" alt="list" />
    //         &nbsp;&nbsp;
    //         {onAdd === true ? (
    //           <span
    //             onClick={this.handleRemoveCol}
    //             style={{ fontWeight: "bold", color: "#fff", fontSize: "10px" }}
    //           >
    //             Remove
    //           </span>
    //         ) : (
    //           <span></span>
    //         )}
    //       </div>
    //       <div
    //         style={{
    //           border: "1px solid #eee",
    //           background: "#f8f9fa",
    //           padding: "3%",
    //         }}
    //       >
    //         <Label style={{ fontSize: "17px" }}>Please select</Label>
    //         <div className="mt-2">
    //           <label style={{ fontSize: "15px" }}>
    //             <input
    //               type="radio"
    //               value="option1"
    //               checked={this.state.selectedOption === "option1"}
    //               onChange={this.handleOptionChange}
    //             />
    //             &nbsp;Write your own question
    //           </label>
    //           <br></br>
    //           <label style={{ fontSize: "15px" }}>
    //             <input
    //               type="radio"
    //               value="option2"
    //               checked={this.state.selectedOption === "option2"}
    //               onChange={this.handleOptionChange}
    //             />
    //             &nbsp;Select a question from our list
    //           </label>
    //         </div>
    //         <Input
    //                   type="select"
    //                   id="language_name"
    //                   name="language_name"
    //                   style={{
    //                     backgroundColor: "#F4E4EF",
    //                     letterSpacing: "0.122rem",
    //                     border: "0",
    //                     padding: "12px 30px",
    //                     borderRadius: "50px",
    //                     margin: "10px 0 0",
    //                     font: "normal normal normal 15px/17px Fira Sans",
    //                     fontWeight: "400",
    //                     color: "#43107E",
    //                   }}
    //                   onChange={this.handleLanguageChange}
    //                 >
    //                   <option value="">SELECT A LANGUAGE</option>
    //                   {language.map((data) => (
    //                     <option>{data.language_name}</option>
    //                   ))}
    //                 </Input>
    //         {this.state.showDetails && (
    //           <>
    //             {this.state.selectedOption === "option1" ? (
    //               <div className="mt-2">
    //                 <Label style={{ fontSize: "17px" }}>Question</Label>
    //                 <Input
    //                   type="text"
    //                   name="question"
    //                   id="question"
    //                   onChange={(e) =>
    //                     this.setState({ question: e.target.value })
    //                   }
    //                 />
    //                 <Label style={{ fontSize: "17px" }} className="mt-3">
    //                   Question Description
    //                 </Label>
    //                 <Input
    //                   type="textarea"
    //                   name="description"
    //                   id="description"
    //                   onChange={(e) =>
    //                     this.setState({ description: e.target.value })
    //                   }
    //                 />
    //               </div>
    //             ) : (
    //               <div>
    //                 <Label style={{ fontSize: "17px" }} className="mt-2">
    //                   Select a Category
    //                 </Label>
    //                 <Input
    //                   type="select"
    //                   id="select_category"
    //                   name="select_category"
    //                   style={{
    //                     backgroundColor: "#F4E4EF",
    //                     width: "100%",
    //                     letterSpacing: "0.122rem",
    //                     border: "0",
    //                     padding: "12px 30px",
    //                     borderRadius: "50px",
    //                     font: "normal normal normal 15px/17px Fira Sans",
    //                     fontWeight: "400",
    //                   }}
    //                   onChange={this.handleCategoryChange}
    //                   value={this.state.selectedCategory}
    //                 >
    //                   <option value="">SELECT A CATEGORY</option>
    //                   {category.map((cat, i) => (
    //                     <option key={i} value={cat.id}>
    //                       {cat.category_name}
    //                     </option>
    //                   ))}
    //                 </Input>
    //                 <Label style={{ fontSize: "17px" }} className="mt-2">
    //                   Select a Question
    //                 </Label>
    //                 <Input
    //                   type="select"
    //                   id="second_dropdown"
    //                   name="second_dropdown"
    //                   style={{
    //                     backgroundColor: "#F4E4EF",
    //                     width: "100%",
    //                     letterSpacing: "0.122rem",
    //                     border: "0",
    //                     padding: "12px 30px",
    //                     borderRadius: "50px",
    //                     font: "normal normal normal 15px/17px Fira Sans",
    //                     fontWeight: "400",
    //                   }}
    //                   onChange={this.handleOptionQuestionChange}
    //                 >
    //                   <option value="">SELECT A QUESTION</option>
    //                   {question.map((question, i) => (
    //                     <option key={i} value={question.id}>
    //                       {" "}
    //                       {question.question}{" "}
    //                     </option>
    //                   ))}
    //                 </Input>
    //                 <Label style={{ fontSize: "17px" }} className="mt-2">
    //                   Question<span style={{ color: "#F61C04" }}>*</span>
    //                 </Label>
    //                 <Input
    //                   type="text"
    //                   name="question"
    //                   id="question"
    //                   value={
    //                     selectedQuestionData
    //                       ? selectedQuestionData.question
    //                       : ""
    //                   }
    //                 />
    //                 <Label style={{ fontSize: "17px" }} className="mt-3">
    //                   Question Description
    //                 </Label>
    //                 <Input
    //                   type="textarea"
    //                   name="description"
    //                   id="description"
    //                   value={
    //                     selectedQuestionData
    //                       ? selectedQuestionData.question_description
    //                       : ""
    //                   }
    //                 />
    //               </div>
    //             )}
    //           </>
    //         )}
    //       </div>
    //     </Col>
    //     {/* <Col md='2'></Col> */}
    //   </Row>
    // ));
    return (
      <Fragment>
        <GoogleFontLoader
          fonts={[
            {
              font: 'Martel',
              weights: [300, 700],
            },
            {
              font: 'Fira Sans',
              weights: [300, 700, 400],
            },
          ]}
        />
        <Container fluid={true}>
          <Card>
            <h1
              className="mt-5"
              style={{
                textAlign: 'center',
                font: 'normal normal 300 2.5rem/1.4 Martel',
                color: '#000',
              }}
            >
              Send Questions
            </h1>
            <span
              style={{
                borderBottom: '2px solid #FEC1EB',
                height: '2px',
                width: '102px',
                margin: '20px auto',
              }}
            ></span>
            <CardBody>
              <Form
                onSubmit={this.onInsert}
                style={{ maxWidth: '840px', margin: '0 auto' }}
              >
                <Row>
                  {/* <Col md='2'></Col> */}
                  <Col md="12">
                    <div
                      style={{ font: 'normal normal 300 1.1rem/1.5 Fira Sans' }}
                    >
                      Selecting relevant questions is a great way to keep your
                      story teller{' '}
                      <span style={{ fontWeight: '500' }}>motivated</span> and{' '}
                      <span style={{ fontWeight: '500' }}>inspired</span>.
                    </div>
                  </Col>
                  {/* <Col md='2'></Col> */}
                </Row>
                <Row className="mt-3">
                  {/* <Col md='2'></Col> */}
                  <Col md="12">
                    <div
                      style={{ font: 'normal normal 300 1.1rem/1.5 Fira Sans' }}
                    >
                      Remember to ask them about things you've always wanted
                      them to write down - and make sure their stories{' '}
                      <span style={{ fontWeight: '500' }}>
                        stay in the family for generations to come
                      </span>
                      .
                    </div>
                  </Col>
                  {/* <Col md='2'></Col> */}
                </Row>
                <Row className="mt-3">
                  {/* <Col md='2'></Col> */}
                  <Col md="12">
                    {/* {filteredGiftUserOrderData.map((orderData, index) => ( */}
                    <div
                      style={{
                        font: 'normal normal 300 1.1rem/1.5 Fira Sans',
                        border: '1px solid #E2E2E2',
                        padding: '7px',
                        background: '#f8f9fa',
                      }}
                    >
                      Your created questions will be sent to{' '}
                      <span style={{ fontWeight: '500' }}>
                        {GiftUserOrderData.giftUserName}
                      </span>
                    </div>
                  </Col>
                  {/* <Col md='2'></Col> */}
                </Row>
                {Array.from({ length: numCols }, (_, index) => (
                  <AddAnotherQuestios
                    handleRemoveCol={() => this.handleRemoveCol()}
                    onAdd={this.state.onAdd}
                    setQuestion={(question) => {
                      // console.log('question=====>>', question)
                      this.setState({
                        question: [...this.state.question, question],
                      })
                    }}
                  />
                ))}
                {/* this.state({quition:[...this.state.quition,quition]}) */}
                <Row className="mt-3">
                  {/* <Col md='2'></Col> */}
                  <Col md="12">
                    <button
                      type="button"
                      onClick={this.handleAddCol}
                      style={{
                        background: '#fff',
                        color: '#8A1A75',
                        fontSize: '.9rem',
                        letterSpacing: '.1rem',
                        border: '1px solid #8A1A75',
                        fontWeight: '300',
                        borderRadius: '30px',
                        padding: '0.5rem 1rem',
                        textTransform: 'uppercase',
                        minHeight: '45px',
                      }}
                    >
                      ADD ANOTHER Question
                    </button>
                  </Col>
                  {/* <Col md='2'></Col> */}
                </Row>
                <Row className="mt-3">
                  {/* <Col xs={5}></Col> */}
                  <Col xs={12} style={{ textAlign: 'center' }}>
                    <button
                      type="submit"
                      style={{
                        background: '#8A1A75',
                        letterSpacing: '0.15rem',
                        border: '2px solid #8A1A75',
                        fontWeight: '300',
                        color: '#fff',
                        borderRadius: '30px',
                        padding: '0 20px',
                        textTransform: 'uppercase',
                        minHeight: '45px',
                      }}
                    >
                      Send these Question
                    </button>
                  </Col>
                  {/* <Col xs={4}></Col> */}
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return { loggedInUserId: state.auth.session.user.id }
}

const mapDispatchToProps = {
  addQuestion: myStoryOperations.addQuestion,
  listCategory: myStoryOperations.listCategory,
  listLanguage: myStoryOperations.listLanguage,
  sendQuestion: myStoryOperations.sendQuestion,
  listQuestionByLanguage: myStoryOperations.listQuestionByLanguage,
  listQuestionByCategory: myStoryOperations.listQuestionByCategory,
}

export default connect(mapStateToProps, mapDispatchToProps)(SendQuestions)
