import React, { Fragment, useContext } from 'react'
import { Container, CardBody, Col, Form, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import { myStoryOperations } from '../../state/ducks/story'
import { connect } from 'react-redux'
import GoogleFontLoader from 'react-google-font-loader'
import Header from '../../Layout/Header'
import Footer from '../../Layout/Footer'
import Sidebar from '../../Layout/Sidebar'
import CustomizerContext from '../../_helper/Customizer'
class View extends React.Component {
  static contextType = CustomizerContext
  constructor(props) {
    super(props)
    this.state = {
      selectedQuestionData: null,
      text: '',
      content: '',
      currentQuestionIndex: 0,
      allQuestionData: [],
      hashkey: '',
    }
  }
  async componentDidMount() {
    try {
      const questionId = sessionStorage.getItem('selectedQuestionId')
      const response = await this.props.listContributorQuestionDetails({
        id: questionId,
      })
      const hashkey = sessionStorage.getItem('contributorHashKey')
      if (response.payload) {
        this.setState({
          selectedQuestionData: response.payload,
          text: response.payload.answer,
          content: response.payload.question,
          date: response.payload.date,
          hashkey: hashkey,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  loadQuestionData = async (allQuestionData, index) => {
    const questionId = allQuestionData[index].id
    const response = await this.props.listQuestionDetails({ id: questionId })
    console.log(response)
    if (response.payload) {
      this.setState({
        selectedQuestionData: response.payload,
        text: response.payload.answer,
        content: response.payload.question,
        date: response.payload.date,
        currentQuestionIndex: index,
        allQuestionData: allQuestionData,
      })
    }
  }

  render() {
    const { content, text, date, hashkey } = this.state
    const { layout, sidebarIconType } = this.context
    // const { layout } = useContext(CustomizerContext)
    // const { sidebarIconType } = useContext(CustomizerContext)
    const layout1 = localStorage.getItem('sidebar_layout') || layout
    const sideBarIcon =
      localStorage.getItem('sidebar_icon_type') || sidebarIconType
    return (
      <Fragment>
        <GoogleFontLoader
          fonts={[
            {
              font: 'Martel',
              weights: [400, 500, 300, 700],
            },
            {
              font: 'Fira Sans',
              weights: [300, 700, 500, 400],
            },
          ]}
        />
        <div
          className={`page-wrapper ${layout1}`}
          sidebar-layout={sideBarIcon}
          id="pageWrapper"
        >
          <Header isLoggedinHeader={true} />
          <div className="page-body-wrapper">
            <Sidebar isLoggedinHeader={true} />
            <Container fluid={true}>
              <Row style={{ fontFamily: 'Martel' }}>
                <CardBody>
                  <Form>
                    <Row style={{ display: 'flex', justifyContent: 'center' }}>
                      <h2
                        className="mt-5 mb-3"
                        style={{
                          width: '750px',
                          textAlign: 'center',
                          font: 'normal normal 300 2.5rem/1.4 Martel',
                          color: '#000',
                          lineHeight: '1.3',
                          padding: '10px 0px',
                          position: 'relative',
                        }}
                      >
                        {content}
                      </h2>
                    </Row>
                    <Row style={{ display: 'flex', justifyContent: 'center' }}>
                      <div
                        style={{
                          textAlign: 'center',
                          font: 'normal normal 400 .65rem/1 Fira Sans',
                          textTransform: 'uppercase',
                          letterSpacing: '.08rem',
                          color: '#8A1A75',
                        }}
                      >
                        BY GIFT YOUR STORY ON {date}
                      </div>
                      <div
                        className="mt-3"
                        style={{
                          height: '2px',
                          width: '15%',
                          background: '#7f2b72',
                          display: 'block',
                          position: 'relative',
                          bottom: '-30px',
                          content: '',
                          width: '22%',
                          height: '2px',
                          background: '#7f2b72',
                          margin: '10px 30% 40px',
                        }}
                      ></div>
                    </Row>
                    <Row
                      className="mt-3"
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <div
                        style={{
                          textAlign: 'center',
                          font: 'normal normal 400 .65rem/1 Fira Sans',
                          textTransform: 'uppercase',
                          letterSpacing: '.08rem',
                          color: '#8A1A75',
                        }}
                      >
                        {/* <span className="action_btns"> */}

                        <Link to="/question-details/" className="inline-button">
                          EDIT
                        </Link>
                        <span>|</span>
                        <Link
                          to={`/ContributorDashboard/${hashkey}`}
                          className="inline-button"
                        >
                          MY DASHBOARD
                        </Link>
                        {/* {/* </span> */}

                        {/* <div className="listing-grab"></div> */}
                      </div>
                      <Col xl="5">
                        <div
                          style={{
                            font: 'normal normal 300 1.3rem/1.4 Fira Sans',
                          }}
                          className="mt-4"
                          dangerouslySetInnerHTML={{ __html: text }}
                        ></div>
                      </Col>
                    </Row>

                    <Row
                      className="mt-4"
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <div
                        style={{
                          height: '2px',
                          width: '15%',
                          background: '#7f2b72',
                          display: 'block',
                          position: 'relative',
                          bottom: '-30px',
                          content: '',
                          width: '22%',
                          height: '2px',
                          background: '#7f2b72',
                          margin: '10px 30% 40px',
                        }}
                      ></div>
                    </Row>

                    <Row className="mt-1 mb-5"></Row>
                    <Row className="mt-1 mb-5"></Row>
                  </Form>
                </CardBody>
              </Row>
            </Container>
            <Footer />
          </div>
        </div>
      </Fragment>
    )
  }
}
const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = {
  listContributorQuestionDetails:
    myStoryOperations.listContributorQuestionDetails,
}
export default connect(mapStateToProps, mapDispatchToProps)(View)
