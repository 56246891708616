import React from 'react'

class Logout extends React.Component {
  componentDidMount() {
    this.logoutHandler()
  }

  logoutHandler = async () => {
    localStorage.removeItem('token')
    localStorage.removeItem('authenticated')
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('authenticated')
    sessionStorage.removeItem('user')
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('menus')
    sessionStorage.removeItem('countries')
    sessionStorage.removeItem('roles')
    sessionStorage.removeItem('selectedUserId')
    sessionStorage.removeItem('cities')
    sessionStorage.removeItem('states')
    sessionStorage.removeItem('selectedCountryDetailsId')
    sessionStorage.removeItem('selectedCustomerId')
    sessionStorage.removeItem('customers')
    sessionStorage.removeItem('currency')
    sessionStorage.removeItem('nationalitys')
    sessionStorage.removeItem('paymentType')
    /* Exhibition Specific */
    sessionStorage.removeItem('isExhi')
    sessionStorage.removeItem('auditCycle')
    sessionStorage.removeItem('selectedItemBarCode')
    sessionStorage.removeItem('selectedAuditId')
    sessionStorage.removeItem('sharedExhibitionsId')
    sessionStorage.removeItem('exhibitionsName')
    sessionStorage.removeItem('selectedBaggingCycleId')
    sessionStorage.removeItem('selectedInvoiceNo')
    sessionStorage.removeItem('selectedReceiptNo')
    sessionStorage.removeItem('selectedBaggingCycleName')
    sessionStorage.removeItem('selectedInvoiceStatus')
    sessionStorage.removeItem('selectedReceiptStatus')
    sessionStorage.removeItem('article')
    sessionStorage.removeItem('category')
    sessionStorage.removeItem('language')
    sessionStorage.removeItem('selectedStoryId')
    sessionStorage.removeItem('selectedQuestionId')
    sessionStorage.removeItem('question')
    sessionStorage.removeItem('collection')
    sessionStorage.removeItem('login')
    localStorage.removeItem('login')
    sessionStorage.removeItem('contributor')
    sessionStorage.removeItem('subCollection')
    sessionStorage.removeItem('allQuestionData')
    sessionStorage.removeItem('selectedIsBagging')
    sessionStorage.removeItem('exhibitionCountryDetails')
    sessionStorage.removeItem('activeFullIndex')
    sessionStorage.removeItem('activeIndex')
    sessionStorage.removeItem('activeInsertImageIndex')
    sessionStorage.removeItem('activeNoImageIndex')
    sessionStorage.removeItem('i18nextLng')
    sessionStorage.removeItem('isFullImage')
    sessionStorage.removeItem('isInsertImage')
    sessionStorage.removeItem('isNoImage')
    sessionStorage.removeItem('isSpanVisible')
    sessionStorage.removeItem('selectedColor')
    sessionStorage.removeItem('questionOrder')

    window.open('/Signin/', '_self')
  }
  render() {
    return <span></span>
  }
}

export default Logout
