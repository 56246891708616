import React, { Fragment, useState, useEffect, useContext } from 'react'
import { Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import { Btn, H6 } from '../../../AbstractElements'
import { Password } from '../../../Constant'
import { connect } from 'react-redux'
import logoWhite from '../../../assets/images/logo/logo.png'
import { ToastContainer, toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'
import {
  login,
  fetchListMenusForRole,
  initializeSession,
  authenticated,
} from '../../../state/ducks/auth/operations'
import man from '../../../assets/images/dashboard/profile.png'
import axios from 'axios'
import * as API_URL from '../../../env'
import Header from '../../../Layout/Header'
import Footer from '../../../Layout/Footer'
import Sidebar from '../../../Layout/Sidebar'
import CustomizerContext from '../../../_helper/Customizer'
import '../../Button.css'
import { LogIn } from '../../../Constant'

const Signin = (props) => {
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [togglePassword, setTogglePassword] = useState(false)
  const history = useNavigate()
  const { layout } = useContext(CustomizerContext)
  const { sidebarIconType } = useContext(CustomizerContext)
  // console.log(layout);
  const layout1 = localStorage.getItem('sidebar_layout') || layout
  // console.log(layout1);
  const sideBarIcon =
    localStorage.getItem('sidebar_icon_type') || sidebarIconType
  // console.log(sideBarIcon);

  // const [value, setValue] = useState(localStorage.getItem("profileURL" || man));
  // const [name, setName] = useState(localStorage.getItem("Name"));

  // useEffect(() => {
  //   localStorage.setItem("profileURL", man);
  //   localStorage.setItem("Name", "Emay Walter");
  // }, [value, name]);

  //  useEffect(() => {
  //   // Check if user is already logged in
  //   const user = localStorage.getItem("user");
  //   // console.log("user get==",user);
  //   const token = localStorage.getItem("token");
  //   // console.log("token get==",token);

  //   if (user && token) {
  //     // Redirect to dashboard if logged in
  //     history(`${process.env.PUBLIC_URL}/my-stories/`);
  //   }
  // }, [history]);

  const handleLogin = async (e) => {
    e.preventDefault()
    try {
      const response = await axios.post(
        API_URL.BACKEND_SERVICE_API_URL + '/public/blank-password-login',
        {
          email: email,
        }
      )
      const res = response.data.payload.data
      // console.log("resss===>>", res);
      props.initializeSession({ user: res.customerData, token: res.token })
      localStorage.setItem('user', JSON.stringify(res.customerData))
      localStorage.setItem('token', res.token)
      sessionStorage.setItem('user', JSON.stringify(res.customerData))
      sessionStorage.setItem('token', res.token)

      toast.success('Login  Successfully', { autoClose: 10000 })
      // window.open("localhost:3000/my-stories", "_self");
      // console.log("open---==", history(`${process.env.PUBLIC_URL}/my-stories`));
      history(`${process.env.PUBLIC_URL}/my-stories/`)
    } catch (error) {
      console.log(error)
      toast.error(error.response.data.message)
    }
  }
  const inputStyle = { border: '1px solid #ccc', background: '#fff' }
  return (
    <Fragment>
      <div
        className={`page-wrapper ${layout1}`}
        sidebar-layout={sideBarIcon}
        id="pageWrapper"
      >
        <Header isLoggedinHeader={true} />
        <div className="page-body-wrapper">
          <Sidebar isLoggedinHeader={true} />
          <div className="page-body">
            <Container fluid={true} className="p-0 login-page">
              <Row>
                <h1
                  style={{
                    textAlign: 'center',
                    marginTop: '3%',
                    font: 'normal normal 300 2.5rem/1.4 Martel',
                  }}
                >
                  Login
                </h1>
                <span
                  style={{
                    borderBottom: '1px solid #FEC1EB',
                    height: '2px',
                    width: '102px',
                    margin: '0px auto',
                  }}
                ></span>
                <Col xs="12">
                  <div className="login-card" style={{ background: '#fff' }}>
                    <div
                      className="login-main"
                      style={{ background: '#F7EBF3', marginTop: '-10%' }}
                    >
                      <Link className="logo" to={process.env.PUBLIC_URL}>
                        <img
                          className="img-fluid for-light"
                          src={logoWhite}
                          width="180"
                          height="180"
                          alt="login page"
                        />
                      </Link>
                      <Form className="theme-form" onSubmit={handleLogin}>
                        <FormGroup>
                          <Label className="col-form-label">Email</Label>
                          <Input
                            className="form-control"
                            type="email"
                            style={inputStyle}
                            name="email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                          />
                        </FormGroup>
                        <FormGroup className="position-relative">
                          <Label className="col-form-label">{Password}</Label>
                          <div className="position-relative">
                            <Input
                              className="form-control"
                              style={inputStyle}
                              type={togglePassword ? 'text' : 'password'}
                              name="password"
                              onChange={(e) => setPassword(e.target.value)}
                              value={password}
                            />
                            <div
                              className="show-hide"
                              onClick={() => setTogglePassword(!togglePassword)}
                            >
                              <span
                                className={togglePassword ? '' : 'show'}
                              ></span>
                            </div>
                          </div>
                        </FormGroup>
                        <div className="position-relative form-group mb-0">
                          <div className="checkbox">
                            <Input
                              id="checkbox1"
                              type="checkbox"
                              style={inputStyle}
                            />
                            <Label className="text-muted" for="checkbox1">
                              Remember Me
                            </Label>
                          </div>
                          <a className="link" href="#javascript">
                            Lost Password?
                          </a>
                          {/* <Btn
                            attrBtn={{
                              color: "primary",
                              className: "d-block w-100 mt-2 custom-button",
                              type: "submit",
                            }}
                          >
                            {LogIn}
                          </Btn> */}
                          <button
                            type="submit"
                            className="d-block w-100 mt-2 custom-button"
                            style={{ margin: '0 auto' }}
                          >
                            {LogIn}
                          </button>
                        </div>
                        <div>
                          <H6></H6>
                        </div>
                      </Form>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <Footer />
        </div>
      </div>

      <ToastContainer />
    </Fragment>
  )
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = {
  login,
  fetchListMenusForRole,
  initializeSession,
  authenticated,
}

export default connect(mapStateToProps, mapDispatchToProps)(Signin)
