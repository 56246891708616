import React, { Fragment } from 'react'
import { Container, CardBody, Col, Form, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import { myStoryOperations } from '../../state/ducks/story'
import { connect } from 'react-redux'
import GoogleFontLoader from 'react-google-font-loader'

class View extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedQuestionData: null,
      text: '',
      content: '',
      currentQuestionIndex: 0,
      allQuestionData: [],
    }
  }
  async componentDidMount() {
    try {
      const questionId = sessionStorage.getItem('selectedQuestionId')
      const response = await this.props.listQuestionDetails({ id: questionId })
      // const questionData = response.payload;
      // console.log("response===>>",response);
      if (response.payload) {
        this.setState({
          selectedQuestionData: response.payload,
          text: response.payload.answer,
          content: response.payload.question,
          date: response.payload.date,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  loadQuestionData = async (allQuestionData, index) => {
    const questionId = allQuestionData[index].id
    const response = await this.props.listQuestionDetails({ id: questionId })
    console.log(response)
    if (response.payload) {
      this.setState({
        selectedQuestionData: response.payload,
        text: response.payload.answer,
        content: response.payload.question,
        date: response.payload.date,
        currentQuestionIndex: index,
        allQuestionData: allQuestionData,
      })
    }
  }

  render() {
    const { content, text, date } = this.state
    const ulStyle = {
      display: 'flex',
      padding: '2px 0px 10px 70px',
      fontSize: '12px',
      fontWeight: '400',
    }
    const liStyle = {
      borderRight: '2px solid #FEC1EB',
      padding: '0 10px',
    }
    return (
      <Fragment>
        <GoogleFontLoader
          fonts={[
            {
              font: 'Martel',
              weights: [400, 500, 300, 700],
            },
            {
              font: 'Fira Sans',
              weights: [300, 700, 500, 400],
            },
          ]}
        />
        <Container fluid={true}>
          <Row style={{ fontFamily: 'Martel' }}>
            <CardBody>
              <Form>
                <Row style={{ display: 'flex', justifyContent: 'center' }}>
                  <h2
                    className="mt-5 mb-3"
                    style={{
                      width: '750px',
                      textAlign: 'center',
                      font: 'normal normal 300 2.5rem/1.4 Martel',
                      color: '#000',
                      lineHeight: '1.3',
                      padding: '10px 0px',
                      position: 'relative',
                    }}
                  >
                    {content}
                  </h2>
                </Row>
                <Row style={{ display: 'flex', justifyContent: 'center' }}>
                  <div
                    style={{
                      textAlign: 'center',
                      font: 'normal normal 400 .65rem/1 Fira Sans',
                      textTransform: 'uppercase',
                      letterSpacing: '.08rem',
                      color: '#8A1A75',
                    }}
                  >
                    BY GIFT YOUR STORY ON {date}
                  </div>
                  <div
                    className="mt-3"
                    style={{
                      height: '2px',
                      width: '15%',
                      background: '#7f2b72',
                      display: 'block',
                      position: 'relative',
                      bottom: '-30px',
                      content: '',
                      width: '22%',
                      height: '2px',
                      background: '#7f2b72',
                      margin: '10px 30% 40px',
                    }}
                  ></div>
                </Row>
                <Row
                  className="mt-3"
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <div
                    style={{
                      textAlign: 'center',
                      font: 'normal normal 400 .65rem/1 Fira Sans',
                      textTransform: 'uppercase',
                      letterSpacing: '.08rem',
                      color: '#8A1A75',
                    }}
                  >
                    {/* <span className="action_btns"> */}

                    <Link
                      to="/my-stories/view-question/edit/"
                      className="inline-button"
                    >
                      EDIT
                    </Link>
                    <span>|</span>
                    <Link
                      to="/my-stories/view-question/pdf/"
                      className="inline-button"
                    >
                      PDF
                    </Link>
                    <span>|</span>
                    <Link
                      to="/my-stories/view-question/"
                      className="inline-button"
                    >
                      ALL QUESTIONS
                    </Link>
                    {/* </span> */}

                    {/* <div className="listing-grab"></div> */}
                  </div>
                  <Col xl="5">
                    <div
                      className="mt-4"
                      style={{ font: 'normal normal 300 1.3rem/1.4 Fira Sans' }}
                      dangerouslySetInnerHTML={{ __html: text }}
                    ></div>
                  </Col>
                </Row>

                <Row
                  className="mt-4"
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <div
                    style={{
                      height: '2px',
                      width: '15%',
                      background: '#7f2b72',
                      display: 'block',
                      position: 'relative',
                      bottom: '-30px',
                      content: '',
                      width: '22%',
                      height: '2px',
                      background: '#7f2b72',
                      margin: '10px 30% 40px',
                    }}
                  ></div>
                </Row>

                <Row className="mt-1 mb-5"></Row>
                <Row className="mt-1 mb-5"></Row>
              </Form>
            </CardBody>
          </Row>
        </Container>
      </Fragment>
    )
  }
}
const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = {
  listQuestionDetails: myStoryOperations.listQuestionDetails,
}
export default connect(mapStateToProps, mapDispatchToProps)(View)
