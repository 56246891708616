import React, { Fragment, useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import SvgIcon from '../../Components/Common/Component/SvgIcon'
import CustomizerContext from '../../_helper/Customizer'
import { MENUITEMS, LOGOUTMENUITEMS } from './Menu'
import GoogleFontLoader from 'react-google-font-loader'
import toggleMenu from '../../../src/assets/images/svg-icon/toggle-menu.svg'
import { connect } from 'react-redux'
import axios from 'axios'
import * as API_URL from '../../env.js'
const SidebarMenuItems = ({
  setMainMenu,
  sidebartoogle,
  setNavActive,
  activeClass,
  props,
  loggedInUserName,
  loggedInUserId,
}) => {
  const { layout } = useContext(CustomizerContext)
  const layout1 = sessionStorage.getItem('sidebar_layout') || layout
  const [menuVisible, setMenuVisible] = useState(false)
  const [selectedUserData, setSelectedUserData] = useState(null)
  const id = window.location.pathname.split('/').pop()
  const layoutId = id
  const CurrentPath = window.location.pathname
  const isSignInPage = CurrentPath.includes('/sign-in') // Update this path based on your actual sign-in route

  // const API_URL = {
  //   BACKEND_SERVICE_API_URL: "http://your-api-url" // Replace with your actual API URL
  // };
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (!loggedInUserId) {
          console.log('No user ID found')
          return
        }
        const options = {
          url: `${API_URL.BACKEND_SERVICE_API_URL}/user/get-user-data/${loggedInUserId}`,
          method: 'GET',
          headers: {
            // authorization: sessionStorage.getItem('token')
            authorization: localStorage.getItem('token'),
          },
        }
        let response = await axios(options)

        // Check if response contains expected data structure
        if (response.data && response.data.payload) {
          const userData = response.data.payload
          const firstName = userData.first_name || ''
          const lastName = userData.last_name || ''
          const fullName = `${firstName} ${lastName}`.trim()
          setSelectedUserData(fullName)
        } else {
          console.log('Unexpected response structure:', response.data)
        }
      } catch (err) {
        console.log(err)
      }
    }

    fetchUserData()
  }, [loggedInUserId])

  const toggleMenuClick = () => {
    setMenuVisible(!menuVisible)
  }

  const { t } = useTranslation()

  const toggletNavActive = (item) => {
    if (window.innerWidth <= 991) {
      document.querySelector('.page-header').className =
        'page-header close_icon'
      document.querySelector('.sidebar-wrapper').className =
        'sidebar-wrapper close_icon '
      if (item.type === 'sub') {
        document.querySelector('.page-header').className = 'page-header'
        document.querySelector('.sidebar-wrapper').className = 'sidebar-wrapper'
      }
    }
    if (!item.active) {
      MENUITEMS.map((a) => {
        a.Items.filter((Items) => {
          if (a.Items.includes(item)) Items.active = false
          if (!Items.children) return false
          Items.children.forEach((b) => {
            if (Items.children.includes(item)) {
              b.active = false
            }
            if (!b.children) return false
            b.children.forEach((c) => {
              if (b.children.includes(item)) {
                c.active = false
              }
            })
          })
          return Items
        })
        return a
      })
    }
    item.active = !item.active
    setMainMenu({ mainmenu: MENUITEMS })
  }

  const menuItems = props.isLoggedinHeader ? LOGOUTMENUITEMS : MENUITEMS

  return (
    <>
      {menuItems.map((Item, i) => (
        <Fragment key={i}>
          <GoogleFontLoader
            fonts={[
              {
                font: 'Martel',
                weights: [300, 700],
              },
              {
                font: 'Fira Sans',
                weights: [300, 700, 400],
              },
            ]}
          />
          <div className="row">
            <div className="right-section col-md-5">
              {!isSignInPage && selectedUserData && (
                <li
                  style={{
                    color: '#fff',
                    font: 'normal normal 300 1rem / 1.3 Martel',
                  }}
                >
                  <span>
                    Hi {selectedUserData}{' '}
                    <Link
                      to="/Logout/"
                      className="custom-logout"
                      style={{ color: '#FFFFFF80' }}
                    >
                      [Log out]
                    </Link>
                  </span>
                </li>
              )}
            </div>
            <div className="left-section col-md-7">
              <div className="menuToggleMenu" onClick={toggleMenuClick}>
                <img src={toggleMenu} />
              </div>
              <ul className="mainMenu">
                {Item.Items.map((menuItem, i) => (
                  <li className="sidebar-list" key={i}>
                    {menuItem.type === 'sub' ? (
                      <a
                        href="javascript"
                        className={`sidebar-link sidebar-title ${
                          CurrentPath.includes(menuItem.title.toLowerCase())
                            ? 'active'
                            : ''
                        } ${menuItem.active && 'active'}`}
                        onClick={(event) => {
                          event.preventDefault()
                          setNavActive(menuItem)
                          activeClass(menuItem.active)
                        }}
                      >
                        <SvgIcon
                          className="fill-icon"
                          iconId={`fill-${menuItem.icon}`}
                        />
                        <span>{t(menuItem.title)}</span>
                        {menuItem.badge ? (
                          <label className={menuItem.badge}>
                            {menuItem.badgetxt}
                          </label>
                        ) : (
                          ''
                        )}
                        <div className="according-menu">
                          {menuItem.active ? (
                            <i className="fa fa-angle-down"></i>
                          ) : (
                            <i className="fa fa-angle-right"></i>
                          )}
                        </div>
                      </a>
                    ) : (
                      ''
                    )}
                    {menuItem.type === 'link' ? (
                      <Link
                        to={menuItem.path + '/' + layoutId}
                        className={`sidebar-link sidebar-title link-nav  ${
                          CurrentPath.includes(menuItem.title.toLowerCase())
                            ? 'active'
                            : ''
                        }`}
                        onClick={() => toggletNavActive(menuItem)}
                      >
                        <SvgIcon
                          className="fill-icon"
                          iconId={`fill-${menuItem.icon}`}
                        />
                        <span>{t(menuItem.title)}</span>
                        {menuItem.badge ? (
                          <label className={menuItem.badge}>
                            {menuItem.badgetxt}
                          </label>
                        ) : (
                          ''
                        )}
                      </Link>
                    ) : (
                      ''
                    )}
                    {menuItem.children ? (
                      <ul
                        className="sidebar-submenu"
                        style={
                          layout1 !== 'compact-sidebar compact-small'
                            ? menuItem?.active ||
                              CurrentPath.includes(
                                menuItem?.title?.toLowerCase()
                              )
                              ? sidebartoogle
                                ? {
                                    opacity: 1,
                                    transition: 'opacity 500ms ease-in',
                                  }
                                : { display: 'block' }
                              : { display: 'none' }
                            : { display: 'none' }
                        }
                      >
                        {menuItem.children.map((childrenItem, index) => {
                          return (
                            <li key={index}>
                              {childrenItem.type === 'sub' ? (
                                <a
                                  href="javascript"
                                  className={`${
                                    CurrentPath.includes(
                                      childrenItem?.title?.toLowerCase()
                                    )
                                      ? 'active'
                                      : ''
                                  }`}
                                  onClick={(event) => {
                                    event.preventDefault()
                                    toggletNavActive(childrenItem)
                                  }}
                                >
                                  {t(childrenItem.title)}
                                  <span className="sub-arrow">
                                    <i className="fa fa-chevron-right"></i>
                                  </span>
                                  <div className="according-menu">
                                    {childrenItem.active ? (
                                      <i className="fa fa-angle-down"></i>
                                    ) : (
                                      <i className="fa fa-angle-right"></i>
                                    )}
                                  </div>
                                </a>
                              ) : (
                                ''
                              )}
                              {childrenItem.type === 'link' ? (
                                <Link
                                  to={childrenItem.path + '/' + layoutId}
                                  className={`${
                                    CurrentPath.includes(
                                      childrenItem?.title?.toLowerCase()
                                    )
                                      ? 'active'
                                      : ''
                                  }`}
                                  onClick={() => toggletNavActive(childrenItem)}
                                >
                                  {t(childrenItem.title)}
                                </Link>
                              ) : (
                                ''
                              )}
                              {childrenItem.children ? (
                                <ul
                                  className="nav-sub-childmenu submenu-content"
                                  style={
                                    CurrentPath.includes(
                                      childrenItem?.title?.toLowerCase()
                                    ) || childrenItem.active
                                      ? { display: 'block' }
                                      : { display: 'none' }
                                  }
                                >
                                  {childrenItem.children.map(
                                    (childrenSubItem, key) => (
                                      <li key={key}>
                                        {childrenSubItem.type === 'link' ? (
                                          <Link
                                            to={
                                              childrenSubItem.path +
                                              '/' +
                                              layoutId
                                            }
                                            className={`${
                                              CurrentPath.includes(
                                                childrenSubItem?.title?.toLowerCase()
                                              )
                                                ? 'active'
                                                : ''
                                            }`}
                                            onClick={() =>
                                              toggletNavActive(childrenSubItem)
                                            }
                                          >
                                            {t(childrenSubItem.title)}
                                          </Link>
                                        ) : (
                                          ''
                                        )}
                                      </li>
                                    )
                                  )}
                                </ul>
                              ) : (
                                ''
                              )}
                            </li>
                          )
                        })}
                      </ul>
                    ) : (
                      ''
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <ul
              className={`mainMenu mobile-section ${menuVisible ? 'open' : ''}`}
            >
              {Item.Items.map((menuItem, i) => (
                <li className="sidebar-list" key={i}>
                  {menuItem.type === 'sub' ? (
                    <a
                      href="javascript"
                      className={`sidebar-link sidebar-title ${
                        CurrentPath.includes(menuItem.title.toLowerCase())
                          ? 'active'
                          : ''
                      } ${menuItem.active && 'active'}`}
                      onClick={(event) => {
                        event.preventDefault()
                        setNavActive(menuItem)
                        activeClass(menuItem.active)
                      }}
                    >
                      <SvgIcon
                        className="fill-icon"
                        iconId={`fill-${menuItem.icon}`}
                      />
                      <span>{t(menuItem.title)}</span>
                      {menuItem.badge ? (
                        <label className={menuItem.badge}>
                          {menuItem.badgetxt}
                        </label>
                      ) : (
                        ''
                      )}
                      <div className="according-menu">
                        {menuItem.active ? (
                          <i className="fa fa-angle-down"></i>
                        ) : (
                          <i className="fa fa-angle-right"></i>
                        )}
                      </div>
                    </a>
                  ) : (
                    ''
                  )}
                  {menuItem.type === 'link' ? (
                    <Link
                      to={menuItem.path + '/' + layoutId}
                      className={`sidebar-link sidebar-title link-nav  ${
                        CurrentPath.includes(menuItem.title.toLowerCase())
                          ? 'active'
                          : ''
                      }`}
                      onClick={() => toggletNavActive(menuItem)}
                    >
                      <SvgIcon
                        className="fill-icon"
                        iconId={`fill-${menuItem.icon}`}
                      />
                      <span>{t(menuItem.title)}</span>
                      {menuItem.badge ? (
                        <label className={menuItem.badge}>
                          {menuItem.badgetxt}
                        </label>
                      ) : (
                        ''
                      )}
                    </Link>
                  ) : (
                    ''
                  )}
                  {menuItem.children ? (
                    <ul
                      className="sidebar-submenu"
                      style={
                        layout1 !== 'compact-sidebar compact-small'
                          ? menuItem?.active ||
                            CurrentPath.includes(menuItem?.title?.toLowerCase())
                            ? sidebartoogle
                              ? {
                                  opacity: 1,
                                  transition: 'opacity 500ms ease-in',
                                }
                              : { display: 'block' }
                            : { display: 'none' }
                          : { display: 'none' }
                      }
                    >
                      {menuItem.children.map((childrenItem, index) => {
                        return (
                          <li key={index}>
                            {childrenItem.type === 'sub' ? (
                              <a
                                href="javascript"
                                className={`${
                                  CurrentPath.includes(
                                    childrenItem?.title?.toLowerCase()
                                  )
                                    ? 'active'
                                    : ''
                                }`}
                                onClick={(event) => {
                                  event.preventDefault()
                                  toggletNavActive(childrenItem)
                                }}
                              >
                                {t(childrenItem.title)}
                                <span className="sub-arrow">
                                  <i className="fa fa-chevron-right"></i>
                                </span>
                                <div className="according-menu">
                                  {childrenItem.active ? (
                                    <i className="fa fa-angle-down"></i>
                                  ) : (
                                    <i className="fa fa-angle-right"></i>
                                  )}
                                </div>
                              </a>
                            ) : (
                              ''
                            )}
                            {childrenItem.type === 'link' ? (
                              <Link
                                to={childrenItem.path + '/' + layoutId}
                                className={`${
                                  CurrentPath.includes(
                                    childrenItem?.title?.toLowerCase()
                                  )
                                    ? 'active'
                                    : ''
                                }`}
                                onClick={() => toggletNavActive(childrenItem)}
                              >
                                {t(childrenItem.title)}
                              </Link>
                            ) : (
                              ''
                            )}
                            {childrenItem.children ? (
                              <ul
                                className="nav-sub-childmenu submenu-content"
                                style={
                                  CurrentPath.includes(
                                    childrenItem?.title?.toLowerCase()
                                  ) || childrenItem.active
                                    ? { display: 'block' }
                                    : { display: 'none' }
                                }
                              >
                                {childrenItem.children.map(
                                  (childrenSubItem, key) => (
                                    <li key={key}>
                                      {childrenSubItem.type === 'link' ? (
                                        <Link
                                          to={
                                            childrenSubItem.path +
                                            '/' +
                                            layoutId
                                          }
                                          className={`${
                                            CurrentPath.includes(
                                              childrenSubItem?.title?.toLowerCase()
                                            )
                                              ? 'active'
                                              : ''
                                          }`}
                                          onClick={() =>
                                            toggletNavActive(childrenSubItem)
                                          }
                                        >
                                          {t(childrenSubItem.title)}
                                        </Link>
                                      ) : (
                                        ''
                                      )}
                                    </li>
                                  )
                                )}
                              </ul>
                            ) : (
                              ''
                            )}
                          </li>
                        )
                      })}
                    </ul>
                  ) : (
                    ''
                  )}
                </li>
              ))}
            </ul>
          </div>
        </Fragment>
      ))}
    </>
  )
}

const mapStateToProps = (state) => {
  const user = state.auth?.session?.user || {}
  // console.log("mapStateToProps:", user);
  return {
    loggedInUserId: user.id || null,
    loggedInUserName: user.user_name || '',
    isLoggedinHeader: !!user.id, // Assuming isLoggedinHeader depends on user.id
  }
}

export default connect(mapStateToProps)(SidebarMenuItems)
